<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Edit Teams</h1>
      </div>

      <div class="column is-6">
        <form @submit.prevent="submitTeam1">
          <div class="box">
            <div class="field">
              <label>Team Name</label>
              <div class="control">
                <input type="text" class="input" v-model="team1_name" />
              </div>
            </div>

            <div class="field">
              <label>Team Color</label>
              <div class="control">
                <div class="select">
                  <select v-model="team1_color_id">
                    {{
                      team1_color
                    }}
                    <option
                      v-for="color in colors"
                      v-bind:style="{ color: color.color_html }"
                      v-bind:value="color.id"
                      v-bind:key="color.id"
                    >
                      {{ color.color }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <button class="button is-success is-light">Update Team 1</button>
            </div>
          </div>
        </form>
      </div>

      <div class="column is-6">
        <form @submit.prevent="submitTeam2">
          <div class="box">
            <div class="field">
              <label>Team Name</label>
              <div class="control">
                <input type="text" class="input" v-model="team2_name" />
              </div>
            </div>

            <div class="field">
              <label>Team Color</label>
              <div class="control">
                <div class="select">
                  <select v-model="team2_color_id">
                    {{
                      team2_color
                    }}
                    <option
                      v-for="color in colors"
                      v-bind:style="{ color: color.color_html }"
                      v-bind:value="color.id"
                      v-bind:key="color.id"
                    >
                      {{ color.color }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <button class="button is-success is-light">Update Team 2</button>
            </div>
          </div>
        </form>
      </div>

      <div class="tableCourses">
        <table class="table is-6 is-scrollable">
          <thead>
            <tr>
              <th scope="col">Färg</th>
              <th scope="col">Färdkod HTML</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="color in colors" v-bind:key="color.id" id="color">
              <td>
                <input
                  type="text"
                  class="input"
                  v-model="color.color"
                  @change="
                    editColor({
                      id: color.id,
                      change: {
                        color: color.color,
                      },
                    })
                  "
                />
              </td>
              <td>
                <input
                  type="text"
                  class="input"
                  v-model="color.color_html"
                  @change="
                    editColor({
                      id: color.id,
                      change: {
                        color_html: color.color_html,
                      },
                    })
                  "
                />
              </td>
              <td>
                <button
                  class="delete"
                  @click="confirmDelete(color.id, color.color)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <br />
    <button @click="addColor()" class="button is-primary" id="button_add">
      Lägg till
    </button>
    <br />
    <br />
    <input
      placeholder="Färg"
      class="input is-2"
      type="text"
      v-model="newColor.color"
      id="new_color"
    />
    <input
      placeholder="Färgkod HTML"
      class="input is-2"
      type="text"
      v-model="newColor.color_html"
      id="new_color_html"
    />
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";

export default {
  name: "EditTeams",
  data() {
    return {
      colors: [],
      newColor: { color: "", color_html: "" },
      team1_name: "",
      team1_color: "",
      team2_name: "",
      team2_color: "",
      team1_color_id: "",
      team2_color_id: "",
      teams: this.$store.getters.teamDetail,
    };
  },
  beforeMount() {
    this.getTeams();
    this.getColors();
  },
  methods: {
    async deleteColor(color_id) {
      await axios
        .delete(`/api/v1/colors/${color_id}/`)
        .then(() => {
          toast({
            message: "Färg raderad",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
          this.getColors();
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    async confirmDelete(color_id, color_name) {
      if (confirm("Vänligen bekräfta borttagning av: " + color_name)) {
        await this.deleteColor(color_id);
      } else {
        toast({
          message: "Radering avbruten",
          type: "is-info",
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: "bottom-right",
        });
      }
    },
    async getTeams() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get(`/api/v1/teams/`)
        .then((response) => {
          this.team1_name = response.data[0].name ? response.data[0].name : "";
          this.team2_name = response.data[1].name ? response.data[1].name : "";
          this.team1_color = response.data[0].color.color
            ? response.data[0].color.color
            : "";
          this.team2_color = response.data[1].color.color
            ? response.data[1].color.color
            : "";
          this.team1_color_id = response.data[0].color.id
            ? response.data[0].color.id
            : null;
          this.team2_color_id = response.data[1].color.id
            ? response.data[1].color.id
            : null;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async editColor(data) {
      return axios
        .patch(`/api/v1/colors/${data.id}/`, data.change)
        .then(() => {
          toast({
            message: "Color changed",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    async addColor() {
      this.$store.commit("setIsLoading", true);

      await axios
        .post(`/api/v1/colors/`, this.newColor)
        .then(() => {
          this.getColors();
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async getColors() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get(`/api/v1/colors/`)
        .then((response) => {
          this.colors = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async submitTeam1() {
      this.$store.commit("setIsLoading", true);

      const team = {
        id: this.teams.team_1_id,
        name: this.team1_name,
        color: this.team1_color_id,
      };

      await axios
        .patch(`/api/v1/teams/${this.teams.team_1_id}/`, team)
        .then(async () => {
          this.$store.dispatch("getTeams");
          toast({
            message: "Team was changed",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async submitTeam2() {
      this.$store.commit("setIsLoading", true);

      const team = {
        id: this.teams.team_1_id,
        name: this.team2_name,
        color: this.team2_color_id,
      };

      await axios
        .patch(`/api/v1/teams/${this.teams.team_2_id}/`, team)
        .then(async () => {
          this.$store.dispatch("getTeams");
          toast({
            message: "Team was changed",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
<style scoped>
#new_color {
  max-width: 20%;
}
#new_color_html {
  max-width: 20%;
}
</style>
