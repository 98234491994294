<template>
  <div class="container">
    <div class="columns">
      <div class="column is-4 is-offset-4">
        <h1 class="title">Ryder Kupp</h1>

        <form @submit.prevent="submitForm">
          <div class="field">
            <label>Nuvarande Lösenord</label>
            <div class="control">
              <input
                type="password"
                name="password"
                class="input"
                v-model="password.current_password"
              />
            </div>
          </div>

          <div class="field">
            <label>Nytt Lösenord</label>
            <div class="control">
              <input
                type="password"
                name="password"
                class="input"
                v-model="password.new_password"
              />
            </div>
          </div>

          <div class="field">
            <label>Nytt Lösenord (igen)</label>
            <div class="control">
              <input
                type="password"
                name="password"
                class="input"
                v-model="password.re_new_password"
              />
            </div>
          </div>

          <div class="field">
            <div class="control">
              <button class="button is-success">Byt lösen</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import { toast } from "bulma-toast";

export default {
  name: "Password",
  data() {
    return {
      password: {
        new_password: "",
        re_new_password: "",
        current_password: "",
      },
      errors: [],
    };
  },
  methods: {
    async submitForm() {
      // console.log(JSON.stringify(this.password));
    },
  },
};
</script>

<style scoped></style>
