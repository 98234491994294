<template>
  <nav class="navbar is-dark is-full-mobile">
    <div v-if="authenticated" class="navbar-brand">
      <router-link v-if="authenticated" to="/main" class="navbar-item">
        <strong>{{ user_nickname }}</strong>
      </router-link>
      <router-link to="/teams" class="button is-info"
        ><strong>Lag</strong></router-link
      >
      <router-link
        v-if="isAdmin"
        to="/admin"
        class="button is-success is-hidden-touch"
        ><strong>Admin</strong></router-link
      >
      <button @click="logout()" class="button is-danger">Logga ut</button>
    </div>
    <div v-else class="navbar-brand">
      <router-link to="/" class="navbar-item"
        ><strong>Logga In</strong></router-link
      >
    </div>

    <div class="navbar-menu">
      <div class="navbar-end">
        <router-link to="/past" class="navbar-item"
          ><strong>Tidigare Kupper</strong></router-link
        >

        <div class="navbar-item">
          <div class="buttons"></div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { toast } from "bulma-toast";

export default {
  name: "Navbar",
  data() {
    return {
      user_nickname: sessionStorage.getItem("nickname"),
      authenticated: sessionStorage.getItem("token") != null,
    };
  },
  watch: {
    $route(to, from) {
      if (to.name === "Login" || to.path === "/") {
        // this.logout();
        this.getUser();
      } else if (from.name === "Login") {
        this.getUser();
      }
    },
  },
  computed: {
    // name: function () {
    //   return this.authenticated ? this.user.nickname : "";
    // },
    isAdmin: function () {
      return sessionStorage.getItem("isAdmin") === "true";
    },
  },
  methods: {
    getUser() {
      // Retrieve user information and if user is logged in
      this.user_nickname = sessionStorage.getItem("nickname");
      this.authenticated = sessionStorage.getItem("token") != null;
    },
    async logout() {
      await this.$store.dispatch("logout", this.user);
      if (sessionStorage.getItem("token") === null) {
        await this.$router.push({ path: "/" });
        toast({
          message: "Logged out",
          type: "is-success",
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: "bottom-right",
        });
      } else {
        toast({
          message: "Something went wrong",
          type: "is-danger",
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: "bottom-right",
        });
      }
    },
  },
};
</script>
<style scoped>
.button {
  margin-top: 1.5%;
  margin-right: 1.5%;
  margin-left: 1.5%;
}

.button.is-info {
  background-color: #3ea4d0e6;
  border-color: transparent;
  color: #fff;
}
.button.is-danger {
  background-color: #f14668;
  border-color: transparent;
  color: #fff;
}
</style>
