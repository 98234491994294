<template>
  <div>
    <h1 class="subtitle is-3">Tidigare Kupper</h1>
  </div>
  <br />

  <div class="select is-info">
    <select v-model="selectedYear" @change="test()">
      <option v-for="cup in past_cups" v-bind:value="cup" v-bind:key="cup">
        {{ cup }}
      </option>
    </select>
  </div>
  <br />
  <br />

  <div v-if="resultCup">
    <a><b>Bana</b></a>
    <p>{{ resultCup.course }}</p>
  </div>
  <br />

  <div v-if="resultCup">
    <a><b>MVP</b></a>
    <h1 class="subtitle is-5">
      <b>{{ resultCup.mvp }}</b>
    </h1>
  </div>
  <br />

  <div>
    <div v-if="resultCup">
      <a>
        <b>Vinnare</b>
      </a>
      <br />
      <ul v-for="player in resultCup.winners" v-bind:key="player">
        <b>{{ player }}</b>
      </ul>
    </div>
    <br />
    <br />

    <div v-if="resultCup">
      <a>
        <b>Deltagare</b>
      </a>
      <ul v-for="player in resultCup.participants" v-bind:key="player">
        <p>{{ player }}</p>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Past",
  data() {
    return {
      selectedYear: "",
      past_cups: ["", "2016", "2017", "2018", "2019", "2020", "2021", "2022"],
      past_results: [
        {
          year: "2016",
          winners: [],
          participants: [],
          mvp: "Tobias Gard",
          course: "Landskrona GK",
        },
        {
          year: "2017",
          winners: [],
          participants: [],
          mvp: "Daniel Andersson",
          course: "Landskrona GK",
        },
        {
          year: "2018",
          winners: [
            "Johan Green",
            "Martin Moricz",
            "Kristian Pedersen",
            "Jörn Rix",
          ],
          participants: [
            "Dan Adeholt",
            "Daniel Andersson",
            "Markus Bjerre",
            "Patrik Cairen",
            "Daniel Erkander",
            "Jonas Peterson",
            "Björn Stenquist",
            "Daniel Ståhl",
          ],
          mvp: "Jörn Rix",
          course: "Skuryp GK",
        },
        {
          year: "2019",
          winners: [
            "Daniel Andersson",
            "Ludvig Beirup",
            "Patrik Cairen",
            "Marcus Lindholm",
            "Martin Moricz",
            "William Wendel",
          ],
          participants: [
            "Daniel Erkander",
            "Tobias Gard",
            "Johan Green",
            "Kristian Pedersen",
            "Jonas Peterson",
            "Jörn Rix",
          ],
          mvp: "Daniel Andersson",
          course: "Vittsjö GK",
        },
        {
          year: "2020",
          winners: [
            "Niklas Berg",
            "Nils Hamre",
            "Marcus Lindholm",
            "Jonas Peterson",
            " Henrik Pettersson",
            "Jörn Rix",
          ],
          participants: [
            "Fredrik Agvard",
            "Johan Green",
            "Martin Moricz",
            "Niltz H Olsson",
            "Kristian Pedersen",
            "Björn Stenquist",
          ],
          mvp: "Martin Moricz",
          course: "Vittsjö GK",
        },
        {
          year: "2021",
          winners: [
            "Niklas Berg",
            "Markus Bjerre",
            "Jacob Fryklund",
            "Nils Hamre",
            "Marcus Lindholm",
            "Martin Moricz",
          ],
          participants: [
            "Ludvig Beirup",
            "Kristian Pedersen",
            "Jonas Peterson",
            "Henrik Pettersson",
            "Jörn Rix",
            "Marcus Strandberg",
          ],
          mvp: "Jacob Fryklund",
          course: "Tomelilla GK",
        },
        {
          year: "2022",
          winners: [
            "Ludvig Beirup",
            "Kristian Pedersen",
            "Jonas Peterson",
            "Henrik Pettersson",
            "Jörn Rix",
            "Marcus Strandberg",
          ],
          participants: [
            "Niklas Berg",
            "Daniel Erkander",
            "Jacob Fryklund",
            "Nils Hamre",
            "Marcus Lindholm",
            "Martin Moricz",
          ],
          mvp: "Jacob Fryklund",
          course: "Tomelilla GK",
        },
      ],
    };
  },
  computed: {
    resultCup: function () {
      for (let i = 0; i < this.past_results.length; i++) {
        if (this.selectedYear === this.past_results[i].year) {
          return this.past_results[i];
        }
      }
      return null;
    },
  },
  methods: {
    test() {
      // console.log(this.selectedYear);
      for (let i = 0; i < this.past_results.length; i++) {
        if (this.selectedYear === this.past_results[i].year) {
          // console.log(JSON.stringify(this.past_results[i]));
        }
      }
    },
  },
};
</script>

<style scoped></style>
