import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import Home from "../views/Home.vue";
import Login from "../components/Login.vue";
import Main from "../views/Main.vue";
import Teams from "../views/Teams";
import EditTeams from "@/views/EditTeams";
import CreateMatch from "@/views/CreateMatch";
import Score from "@/views/SetScore";
import Admin from "@/views/Admin";
import Past from "@/views/Past";
import Password from "@/views/Password";

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/past",
    name: "Past",
    component: Past,
    meta: { title: "Kuppens Historia" },
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { title: "Logga in" },
  },
  {
    path: "/main",
    name: "Main",
    component: Main,
    meta: {
      requireLogin: true,
      title: "Kuppen Live",
    },
  },
  {
    path: "/pass",
    name: "Password",
    component: Password,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/main/score/:id",
    name: "Score",
    component: Score,
    meta: {
      requireLogin: true,
      title: "Registrera resultat",
    },
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: {
      requireLogin: true,
      isAdmin: true,
      title: "Kuppen Admin",
    },
  },
  {
    path: "/creatematch",
    name: "CreateMatch",
    component: CreateMatch,
    meta: {
      requireLogin: true,
      isAdmin: true,
      title: "Skapa Match",
    },
  },
  {
    path: "/teams",
    name: "Teams",
    component: Teams,
    meta: {
      requireLogin: true,
      title: "Kuppens Lag",
    },
  },
  {
    path: "/EditTeams",
    name: "EditTeams",
    component: EditTeams,
    meta: {
      requireLogin: true,
      isAdmin: true,
      title: "Ändra kupplag",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (
//     to.matched.some((record) => record.meta.requireLogin) &&
//     !store.state.isAuthenticated
//   ) {
//     next("/Login");
//   } else {
//     next();
//   }
// });

router.beforeEach(async (to, from, next) => {
  /** Initial check if user is logged in */

  if (to.matched.some((record) => record.meta.requireLogin)) {
    let isLoggedIn = sessionStorage.getItem("token") != null;
    if (!isLoggedIn) {
      next({ name: "Login" });
    } else {
      if (to.matched.some((record) => record.meta.isAdmin)) {
        console.log("requires admin");
        let isAdmin = await store.getters.isAdmin;
        if (!isAdmin) {
          next({ path: "/main" });
          console.log("not admin");
        }
        next();
      } else {
        next();
        console.log("logged in - route to next");
      }
    }
  } else {
    next();
  }
});

export default router;
