<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">Create Match</h1>
      </div>

      <div class="column is-6">
        <form @submit.prevent="submitForm">
          <div v-if="matchtypes_exist" class="field">
            <label>Match Type</label>
            <div class="control">
              <div class="select">
                <select v-model="match.type">
                  <option
                    v-for="matchtype in matchtypes"
                    v-bind:value="matchtype.id"
                    v-bind:key="matchtype.id"
                  >
                    {{ matchtype.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div v-else class="button is-success" @click="generateMatchtypes()">
            Generera Matchtyper
          </div>

          <div
            class="field"
            v-if="type_chosen"
            :style="{ color: teams.team_1_color }"
          >
            <label>Player Team {{ teams.team_1_color }}</label>
            <div class="control">
              <div class="select">
                <select v-model="match.contestant_1_1">
                  <option
                    v-for="player in playersteam1"
                    v-bind:key="player.id"
                    v-bind:style="{ color: teams.team_1_color }"
                    v-bind:value="player.id"
                  >
                    {{ player.nickname }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="field"
            v-if="multiplayer"
            :style="{ color: teams.team_1_color }"
          >
            <label>Player Team {{ teams.team_1_color }}</label>
            <div class="control">
              <div class="select">
                <select v-model="match.contestant_1_2">
                  <option
                    v-for="player in playersteam1"
                    v-bind:key="player.id"
                    v-bind:style="{ color: teams.team_1_color }"
                    v-bind:value="player.id"
                  >
                    {{ player.nickname }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <br />
          <h1 class="title is-5" v-if="type_chosen">VS</h1>
          <br />

          <div
            class="field"
            v-if="type_chosen"
            :style="{ color: teams.team_2_color }"
          >
            <label>Player Team {{ teams.team_2_color }}</label>
            <div class="control">
              <div class="select">
                <select v-model="match.contestant_2_1">
                  <option
                    v-for="player in playersteam2"
                    v-bind:key="player.id"
                    v-bind:style="{ color: teams.team_2_color }"
                    v-bind:value="player.id"
                  >
                    {{ player.nickname }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="field"
            v-if="multiplayer"
            :style="{ color: teams.team_2_color }"
          >
            <label>Player Team {{ teams.team_2_color }}</label>
            <div class="control">
              <div class="select">
                <select v-model="match.contestant_2_2">
                  <option
                    v-for="player in playersteam2"
                    v-bind:key="player.id"
                    v-bind:style="{ color: teams.team_2_color }"
                    v-bind:value="player.id"
                  >
                    {{ player.nickname }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="field" v-if="match.type.length !== 0">
            <div class="control">
              <button
                class="button is-success is-light"
                :disabled="FieldsFilled"
              >
                Save Match
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "CreateMatch",
  data() {
    return {
      matchtypes: [],
      match: {
        type: "",
        contestant_1_1: "",
        contestant_1_2: "",
        contestant_2_1: "",
        contestant_2_2: "",
      },
      teams: this.$store.getters.teamDetail,
      playersteam1: [],
      playersteam2: [],
      match_id: 0,
    };
  },
  computed: {
    matchtypes_exist: function () {
      return this.matchtypes.length > 0;
    },
    type_chosen: function () {
      return this.match.type.length !== 0;
    },
    multiplayer: function () {
      let multiplayer = false;
      for (let i = 0; i < this.matchtypes.length; i++) {
        if (this.matchtypes[i].id === this.match.type) {
          multiplayer = this.matchtypes[i].multiplayer;
        }
      }
      return multiplayer;
    },
    FieldsFilled: function () {
      if (this.multiplayer) {
        return !!(
          this.match.contestant_1_1.length &&
          this.match.contestant_1_2.length &&
          this.match.contestant_2_1.length &&
          this.match.contestant_2_2.length
        );
      } else {
        return !!(
          this.match.contestant_1_1.length && this.match.contestant_2_1.length
        );
      }
    },
  },
  beforeMount() {
    this.getMatchTypes();
    this.getPlayers();
  },
  methods: {
    async generateMatchtypes() {
      this.$store.commit("setIsLoading", true);

      await axios
        .post("api/v1/matchtypes/initiate/")
        .then(() => {
          toast({
            message: "Matchtypes generated",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
          this.getMatchTypes();
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getPlayers() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("api/v1/users")
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].team === 1) {
              this.playersteam1.push(response.data[i]);
            } else if (response.data[i].team === 2) {
              this.playersteam2.push(response.data[i]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getMatchTypes() {
      this.$store.commit("setIsLoading", true);

      axios
        .get(`/api/v1/matchtypes/`)
        .then((response) => {
          this.matchtypes = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      // console.log(JSON.stringify(this.match));

      await axios
        .post(`/api/v1/matches/`, this.match)
        .then(() => {
          toast({
            message: "Match created",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
