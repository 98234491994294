<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <router-link to="/CreateMatch" class="button is-success"
          ><strong>Create Match</strong></router-link
        >
        <br />
        <br />
      </div>

      <div class="column is-8">
        <div>
          <h1 class="subtitle">Foursomes</h1>
        </div>
        <div class="box">
          <table class="table is-full-mobile">
            <thead>
              <tr>
                <th>Lag</th>
                <th>Spelare</th>
                <th></th>
                <th>Spelare</th>
                <th>Lag</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="match in foursome_matches" v-bind:key="match.id">
                <td :style="{ color: teams.team_1_color }">
                  {{ teams.team_1_color }}
                </td>
                <td>
                  {{ match.contestant_1_1.nickname }} /
                  {{ match.contestant_1_2.nickname }}
                </td>
                <td>VS</td>
                <td>
                  {{ match.contestant_2_1.nickname }} /
                  {{ match.contestant_2_2.nickname }}
                </td>
                <td :style="{ color: teams.team_2_color }">
                  {{ teams.team_2_color }}
                </td>
                <td
                  class="button"
                  style="color: forestgreen"
                  @click="activateMatch(match.id)"
                >
                  &#128077;
                </td>
                <td
                  class="button"
                  style="color: darkred"
                  @click="deleteMatch(match.id)"
                >
                  X
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="column is-8">
        <div>
          <h1 class="subtitle">Bestballs</h1>
        </div>
        <div class="box">
          <table class="table is-full-mobile">
            <thead>
              <tr>
                <th>Team</th>
                <th>Player</th>
                <th></th>
                <th>Player</th>
                <th>Team</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="match in bestball_matches" v-bind:key="match.id">
                <td :style="{ color: teams.team_1_color }">
                  {{ teams.team_1_color }}
                </td>
                <td>
                  {{ match.contestant_1_1.nickname }} /
                  {{ match.contestant_1_2.nickname }}
                </td>
                <td>VS</td>
                <td>
                  {{ match.contestant_2_1.nickname }} /
                  {{ match.contestant_2_2.nickname }}
                </td>
                <td :style="{ color: teams.team_2_color }">
                  {{ teams.team_2_color }}
                </td>
                <td
                  class="button"
                  style="color: forestgreen"
                  @click="activateMatch(match.id)"
                >
                  &#128077;
                </td>
                <td
                  class="button"
                  style="color: darkred"
                  @click="deleteMatch(match.id)"
                >
                  X
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="column is-8">
        <div>
          <h1 class="subtitle">Singles</h1>
        </div>
        <div class="box">
          <table class="table is-full">
            <thead>
              <tr>
                <th>Team</th>
                <th>Player</th>
                <th></th>
                <th>Player</th>
                <th>Team</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="match in single_matches" v-bind:key="match.id">
                <td :style="{ color: teams.team_1_color }">
                  {{ teams.team_1_color }}
                </td>
                <td>
                  <b>{{ match.contestant_1_1.nickname }}</b>
                </td>
                <td>AS</td>
                <td>{{ match.contestant_2_1.nickname }}</td>
                <td :style="{ color: teams.team_2_color }">
                  {{ teams.team_2_color }}
                </td>
                <td
                  class="button"
                  style="color: forestgreen"
                  @click="activateMatch(match.id)"
                >
                  &#128077;
                </td>
                <td
                  class="button"
                  style="color: darkred"
                  @click="deleteMatch(match.id)"
                >
                  X
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "MatchesComponent",
  data() {
    return {
      single_matches: [],
      foursome_matches: [],
      bestball_matches: [],
      teams: this.$store.getters.teamDetail,
    };
  },
  beforeMount() {
    this.getMatches();
  },
  methods: {
    async getMatches() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("api/v1/matches?status=CREATED")
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].type.name === "Single") {
              this.single_matches.push(response.data[i]);
            } else if (response.data[i].type.name === "Foursome") {
              this.foursome_matches.push(response.data[i]);
            } else if (response.data[i].type.name === "Fourball") {
              this.bestball_matches.push(response.data[i]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.$store.commit("setIsLoading", false);
    },
    async deleteMatch(match_id) {
      this.$store.commit("setIsLoading", true);

      axios
        .delete(`/api/v1/matches/${match_id}/`)
        .then(() => {
          toast({
            message: "Match deleted",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      window.location.reload();

      this.$store.commit("setIsLoading", false);
    },
    async activateMatch(match_id) {
      this.$store.commit("setIsLoading", true);

      const activate = {
        status: "ACTIVE",
      };

      axios
        .patch(`/api/v1/matches/${match_id}/`, activate)
        .then(() => {
          toast({
            message: "Match activated",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 3000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      window.location.reload();

      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
