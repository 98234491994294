<template>
  <div class="columns">
    <div class="column is-4">
      <div class="title is-3">Banor</div>
      <div class="tableCourses">
        <table class="table is-6 is-scrollable">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Bana</th>
              <th scope="col">Vald</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="course in courses" v-bind:key="course.id" id="userID">
              <td>
                <button
                  v-if="course.id !== selectedCourse"
                  class="button is-info"
                  @click="displayCourseData(course.id)"
                >
                  Visa info
                </button>
                <button v-else class="button is-info" @click="hideCourseData()">
                  Dölj info
                </button>
              </td>
              <td>
                <input
                  type="text"
                  class="input"
                  v-model="course.name"
                  @change="
                    editCourse({
                      id: course.id,
                      change: {
                        name: course.name,
                      },
                    })
                  "
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  class="checkbox"
                  :disabled="course.selected ? false : blockSelected"
                  v-model="course.selected"
                  @change="
                    editCourse({
                      id: course.id,
                      change: {
                        selected: course.selected,
                      },
                    })
                  "
                />
              </td>
              <td>
                <button
                  class="delete"
                  @click="confirmDeleteCourse(course.id, course.selected)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
      <br />
      <button @click="addCourse()" class="button is-primary" id="button_add">
        Lägg till
      </button>
      <input
        class="input is-2"
        type="text"
        v-model="newCourse.name"
        id="new_course"
      />
    </div>

    <div class="column is-3" v-if="courseData">
      <div>
        <div class="title is-3" id="Info_title">Info</div>
        <table class="table is-small is-3" id="course_info">
          <thead>
            <tr>
              <th>Hål</th>
              <th>Par</th>
              <th>Hcp</th>
              <th>Längd</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hole in courseData" v-bind:key="hole.id">
              <td>{{ hole.hole }}</td>
              <td>
                <input
                  type="text"
                  class="input is-1 is-small"
                  v-model="hole.par"
                  @change="
                    editCourseData({
                      id: hole.id,
                      change: {
                        par: hole.par,
                      },
                    })
                  "
                />
              </td>
              <td>
                <input
                  type="text"
                  class="input is-1 is-small"
                  v-model="hole.index"
                  @change="
                    editCourseData({
                      id: hole.id,
                      change: {
                        index: hole.index,
                      },
                    })
                  "
                />
              </td>
              <td>
                <input
                  type="text"
                  class="input is-1 is-small"
                  v-model="hole.length"
                  @change="
                    editCourseData({
                      id: hole.id,
                      change: {
                        length: hole.length,
                      },
                    })
                  "
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="column is-4" v-if="courseSlope">
      <div>
        <div class="title is-4">Slope</div>
        <table class="table is-4 is-small" id="course_slope">
          <thead>
            <tr>
              <th>
                <button class="button is-primary" @click="addSlopeEntry()">
                  Add
                </button>
              </th>
              <th>Från</th>
              <th>Till</th>
              <th>Slag</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entry in courseSlope" v-bind:key="entry.id">
              <td></td>
              <td>
                <input
                  type="number"
                  class="input is-2 is-small"
                  v-model="entry.hcp_low"
                  @change="
                    editCourseSlope({
                      id: entry.id,
                      change: {
                        hcp_low: entry.hcp_low,
                      },
                    })
                  "
                />
              </td>
              <td>
                <input
                  type="number"
                  class="input is-2 is-small"
                  v-model="entry.hcp_high"
                  @change="
                    editCourseSlope({
                      id: entry.id,
                      change: {
                        hcp_high: entry.hcp_high,
                      },
                    })
                  "
                />
              </td>
              <td>
                <input
                  type="number"
                  class="input is-2 is-small"
                  v-model="entry.strokes"
                  @change="
                    editCourseSlope({
                      id: entry.id,
                      change: {
                        strokes: entry.strokes,
                      },
                    })
                  "
                />
              </td>
              <td>
                <button
                  class="delete"
                  @click="confirmDeleteSlope(entry.id)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";

export default {
  name: "CoursesComponent",
  data() {
    return {
      courses: null,
      newCourse: { name: "" },
      courseData: null,
      courseSlope: null,
      selectedCourse: null,
    };
  },
  computed: {
    blockSelected: function () {
      let selected = 0;
      for (let i = 0; i < this.courses.length; i++) {
        if (this.courses[i].selected) {
          selected++;
        }
      }
      return selected > 0;
    },
  },
  beforeMount() {
    this.getCourses();
  },
  methods: {
    // isLastListItem(row_index, list) {
    //  return row_index + 1 === list.length;
    // },
    test() {
      // console.log(JSON.stringify(this.selectedCourse));
    },
    confirmDeleteSlope(entry_id) {
      if (confirm("Vänligen bekräfta borttagning?")) {
        this.deleteSlopeEntry(entry_id);
      } else {
        toast({
          message: "Radering avbruten",
          type: "is-info",
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: "bottom-right",
        });
      }
    },
    confirmDeleteCourse(course_id, selected) {
      if (selected) {
        toast({
          message: "Vald bana kan inte raderas",
          type: "is-danger",
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: "bottom-right",
        });
      } else if (confirm("Vänligen bekräfta borttagning?")) {
        this.deleteCourse(course_id);
      } else {
        toast({
          message: "Radering avbruten",
          type: "is-info",
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: "bottom-right",
        });
      }
    },
    async getCourses() {
      await axios.get("api/v1/courses/").then((response) => {
        this.courses = response.data;
      });
    },
    async getSlope() {
      await axios
        .get("api/v1/courseslope?course=" + this.selectedCourse)
        .then((response) => {
          this.courseSlope = response.data;
          // console.log(JSON.stringify(this.courseSlope));
        });
    },
    async deleteSlopeEntry(entry_id) {
      await axios
        .delete(`/api/v1/courseslope/${entry_id}/`)
        .then(() => {
          toast({
            message: "Slope-rad raderad",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
          this.getSlope();
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    async deleteCourse(course_id) {
      await axios
        .delete(`/api/v1/courses/${course_id}/`)
        .then(() => {
          toast({
            message: "Bana raderad",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
          this.getCourses();
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    async addCourse() {
      await axios
        .post("api/v1/courses/", this.newCourse)
        .then(async (response) => {
          toast({
            message: "Course added",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
          await this.getCourses();
          await this.displayCourseData(response.data.id);
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    editCourseData(data) {
      console.log(JSON.stringify(data.change));
      return axios
        .patch(`/api/v1/coursedata/${data.id}/`, data.change)
        .then(() => {
          toast({
            message: "Course data changed",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    editCourseSlope(data) {
      return axios
        .patch(`/api/v1/courseslope/${data.id}/`, data.change)
        .then(() => {
          toast({
            message: "Course slope changed",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    async addSlopeEntry() {
      let payload = {
        hcp_low: 0.0,
        hcp_high: 0.0,
        strokes: 0,
        course: this.selectedCourse,
      };
      await axios
        .post("api/v1/courseslope/", payload)
        .then(async () => {
          toast({
            message: "New slope line added",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
          await this.getSlope();
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    editCourse(data) {
      return axios
        .patch(`/api/v1/courses/${data.id}/`, data.change)
        .then(() => {
          toast({
            message: "Course changed",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    async displayCourseData(course_id) {
      this.selectedCourse = course_id;
      for (let i = 0; i < this.courses.length; i++) {
        console.log(i);
        if (this.courses[i].id === course_id) {
          this.courseData = this.courses[i].course_data;
        }
        await this.getSlope();
      }
    },
    hideCourseData() {
      this.courseData = null;
      this.selectedCourse = null;
      this.courseSlope = null;
    },
  },
};
</script>

<style scoped>
thead > th > :nth-child(1) {
  width: 50px;
}

#course_info {
  margin-left: 20px;
}

#course_slope {
  margin-left: 80px;
}

#new_course {
  max-width: 66%;
  margin-left: 7%;
}

.is-small.input {
  border-radius: 2px;
  font-size: 0.75rem;
  max-width: 80px;
}

.title {
  margin-left: 160px;
  color: navy;
}
#Info_title {
  margin-left: 80px;
}

#button_add {
  margin-left: 12px;
}
</style>
