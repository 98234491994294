<template>
  <div class="container">
    <div class="columns">
      <div class="column is-4 is-offset-4">
        <h1 class="title">Ryder Kupp</h1>

        <form @submit.prevent="submitForm">
          <div class="field">
            <label>Email</label>
            <div class="control">
              <input
                type="email"
                name="email"
                class="input"
                v-model="user.username"
              />
            </div>
          </div>

          <div class="field">
            <label>Password</label>
            <div class="control">
              <input
                type="password"
                name="password"
                class="input"
                v-model="user.password"
              />
            </div>
          </div>

          <div class="notification is-danger" v-if="errors.length">
            <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
          </div>

          <div class="field">
            <div class="control">
              <button class="button is-success">Logga In</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { toast } from "bulma-toast";
// import router from "@/router";

export default {
  name: "Login",
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      errors: [],
    };
  },
  methods: {
    async submitForm() {
      await this.$store.dispatch("login", this.user);
      console.log(sessionStorage.getItem("token") != null);
      if (sessionStorage.getItem("token") != null) {
        await this.$router.push({ name: "Main" });
        console.log("route");
      } else {
        toast({
          message: "Wrong Username/Password",
          type: "is-danger",
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: "bottom-right",
        });
      }
    },
  },
};
</script>

<style scoped></style>
