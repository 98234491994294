<template>
  <div class="home">
    <img
      alt="Kuppen"
      src="../assets/happy-gilmore.png"
      style="vertical-align: middle"
    />
  </div>
  <div>
    <br />
    <router-link to="/Login" class="button is-success" align="center"
      ><strong>Logga In</strong></router-link
    >
  </div>
</template>

<script>
// import Login from "@/components/Login";

export default {
  name: "Home",
  watch: {
    $route(from, to) {
      if (from || to) {
        this.$router.push({ name: "Login" });
      }
    },
  },
};
</script>
