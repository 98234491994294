<template>
  <div class="tabs is-toggle is-centered">
    <ul>
      <li v-bind:class="{ 'is-active': isActivePlayers === 'edit' }">
        <a v-on:click="isActivePlayers = 'edit'">Ändra</a>
      </li>
      <li v-bind:class="{ 'is-active': isActivePlayers === 'add' }">
        <a v-on:click="isActivePlayers = 'add'">Skapa</a>
      </li>
    </ul>
  </div>

  <div class="tab-contents">
    <div
      class="content"
      v-bind:class="{ 'is-active': isActivePlayers === 'edit' }"
    >
      <!--      edit content-->
      <div id="show-table">
        <div class="tableUsers">
          <table class="table is-fullwidth is-scrollable">
            <thead>
              <tr class="header">
                <th scope="col">Förnamn</th>
                <th scope="col">Efternamn</th>
                <th scope="col">Namn</th>
                <th scope="col">Admin</th>
                <th scope="col">Golf-Id</th>
                <th scope="col">Hcp</th>
                <th scope="col">Lag</th>
                <th scope="col">Email</th>
                <th scope="col">Aktiv</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users" v-bind:key="user.id" id="userID">
                <td>{{ user.first_name }}</td>
                <td>{{ user.last_name }}</td>
                <td>
                  <input
                    type="text"
                    class="input"
                    v-model="user.nickname"
                    @change="
                      editPlayer({
                        id: user.player_id,
                        change: {
                          nickname: user.nickname,
                        },
                      })
                    "
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    class="checkbox"
                    v-model="user.is_staff"
                    @change="
                      editUser({
                        id: user.id,
                        change: {
                          is_staff: user.is_staff,
                        },
                      })
                    "
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="input"
                    v-model="user.golf_id"
                    @change="
                      editPlayer({
                        id: user.player_id,
                        change: {
                          golf_id: user.golf_id,
                        },
                      })
                    "
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="input"
                    v-model="user.hcp"
                    @change="
                      editPlayer({
                        id: user.player_id,
                        change: {
                          hcp: user.hcp,
                        },
                      })
                    "
                  />
                </td>
                <td>
                  <select
                    v-model="user.team"
                    class="select"
                    :style="
                      user.team === 1
                        ? { color: teams.team_1_color }
                        : { color: teams.team_2_color }
                    "
                    @change="
                      editPlayer({
                        id: user.player_id,
                        change: {
                          team: user.team,
                        },
                      })
                    "
                  >
                    <option :value="null"></option>
                    <option
                      :style="{ color: teams.team_1_color }"
                      :value="teams.team_1_id"
                    >
                      {{ teams.team_1_name }}
                    </option>
                    <option
                      :style="{ color: teams.team_2_color }"
                      :value="teams.team_2_id"
                    >
                      {{ teams.team_2_name }}
                    </option>
                  </select>
                </td>
                <td>{{ user.email }}</td>
                <td>
                  <input
                    type="checkbox"
                    class="checkbox"
                    :disabled="user.is_staff"
                    v-model="user.is_active"
                    @change="
                      editUser({
                        id: user.id,
                        change: {
                          is_active: user.is_active,
                        },
                      })
                    "
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="content"
      v-bind:class="{ 'is-active': isActivePlayers === 'add' }"
    >
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <h1 class="title">Add Player</h1>
          </div>
          <div class="column is-4">
            <form @submit.prevent="submitForm">
              <div class="field">
                <label>First Name</label>
                <div class="control">
                  <input type="text" class="input" v-model="user.first_name" />
                </div>
              </div>

              <div class="field">
                <label>Last Name</label>
                <div class="control">
                  <input type="text" class="input" v-model="user.last_name" />
                </div>
              </div>

              <div class="field">
                <label>Nickname</label>
                <div class="control">
                  <input type="text" class="input" v-model="player.nickname" />
                </div>
              </div>

              <div class="field">
                <label>Email</label>
                <div class="control">
                  <input type="email" class="input" v-model="user.email" />
                </div>
              </div>

              <div class="field">
                <label>Golf Id</label>
                <div class="control">
                  <input type="text" class="input" v-model="player.golf_id" />
                </div>
              </div>

              <div class="field">
                <label>Hcp</label>
                <div class="control">
                  <input
                    id="hcp"
                    type="text"
                    class="input"
                    v-model="player.hcp"
                  />
                </div>
              </div>

              <div class="field">
                <label>Team</label>
                <div class="control">
                  <div class="select">
                    <select v-model="player.team">
                      <option
                        :style="{ color: teams.team_1_color }"
                        :value="teams.team_1_id"
                      >
                        {{ teams.team_1_color }}
                      </option>
                      <option
                        :style="{ color: teams.team_2_color }"
                        :value="teams.team_2_id"
                      >
                        {{ teams.team_2_color }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="field">
                <label>Password</label>
                <div class="control">
                  <input
                    type="password"
                    class="input"
                    id="password"
                    v-model="user.password"
                    onfocus="this.type = 'text'"
                    onblur="this.type = 'password'"
                  />
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <button class="button is-success is-light">Add Player</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";

export default {
  name: "PlayerComponent",
  data() {
    return {
      isActivePlayers: "edit",
      user: {
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
      },
      player: {
        fullname: "",
        nickname: "",
        golf_id: "",
        hcp: "",
        team: null,
        user: null,
      },
      teams: this.$store.getters.teamDetail,
      users: [],
      user_created: false,
    };
  },
  beforeMount() {
    this.getUsers();
  },
  methods: {
    test() {
      // console.log(JSON.stringify(this.users));
    },
    editPlayer(data) {
      return axios
        .patch(`/api/v1/players/${data.id}/`, data.change)
        .then(() => {
          toast({
            message: "Player changed",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      // await this.getUsers; not needed?
    },
    editUser(data) {
      return axios
        .patch(`/api/v1/users/${data.id}/`, data.change)
        .then(() => {
          toast({
            message: "Player changed",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      // await this.getUsers; not needed?
    },
    async submitForm() {
      await this.addUser();
      if (this.user_created) {
        await this.addPlayerInfo();
      }
      // console.log(JSON.stringify(this.user));
      // console.log(JSON.stringify(this.player));
    },
    async getUsers() {
      await axios.get("api/v1/users/").then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].first_name && response.data[i].last_name) {
            this.users.push(response.data[i]);
          }
        }
        // console.log(JSON.stringify(this.users));
        // console.log(JSON.stringify(response.data));
      });
    },
    async addUser() {
      this.$store.commit("setIsLoading", true);
      this.user.username = this.user.email;
      // console.log(JSON.stringify(this.user));

      await axios
        .post("api/v1/users/", this.user)
        .then((response) => {
          this.player.user = response.data.id;
          this.user_created = true;
        })
        .catch((error) => {
          toast({
            message: "Something went wrong when creating user",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
          console.log(JSON.stringify(error));
          this.$store.commit("setIsLoading", false);
        });
    },
    async addPlayerInfo() {
      this.player.fullname = this.user.first_name + " " + this.user.last_name;
      // console.log(JSON.stringify(this.player));

      await axios
        .post("api/v1/players/", this.player)
        .then((response) => {
          console.log(response);
          toast({
            message: "Player " + response.data.nickname + " added",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
          this.isActive = "edit";
          //this.$router.push('/Players')
          this.getUsers();
        })
        .catch((error) => {
          toast({
            message: "Something went wrong when adding player info",
            type: "is-danger",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>

<style scoped>
.tab-contents .content {
  display: none;
}
.tab-contents .content.is-active {
  display: block;
}

.table.is-scrollable {
  overflow-y: scroll;
  width: auto;
  position: absolute;
}
</style>
