<template>
  <div class="tabs is-centered is-toggle">
    <ul>
      <li v-bind:class="{ 'is-active': isActive === 'players' }">
        <a v-on:click="isActive = 'players'">Spelare</a>
      </li>
      <li v-bind:class="{ 'is-active': isActive === 'matches' }">
        <a v-on:click="isActive = 'matches'">Matcher</a>
      </li>
      <li v-bind:class="{ 'is-active': isActive === 'contests' }">
        <a v-on:click="isActive = 'contests'">Tävlingar</a>
      </li>
      <li v-bind:class="{ 'is-active': isActive === 'courses' }">
        <a v-on:click="isActive = 'courses'">Banor</a>
      </li>
    </ul>
  </div>

  <div class="tab-contents">
    <div class="content" v-bind:class="{ 'is-active': isActive === 'players' }">
      <!--      players content-->
      <PlayerComponent />
    </div>

    <div class="content" v-bind:class="{ 'is-active': isActive === 'matches' }">
      <!--      matches content-->
      <MatchesComponent />
    </div>

    <div
      class="content"
      v-bind:class="{ 'is-active': isActive === 'contests' }"
    >
      <!--      contests content-->
      <ContestsComponent />
    </div>

    <div class="content" v-bind:class="{ 'is-active': isActive === 'courses' }">
      <!--      total content-->
      <CoursesComponent />
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import { toast } from "bulma-toast";
import PlayerComponent from "@/components/Players";
import MatchesComponent from "@/components/Matches";
import CoursesComponent from "@/components/Courses";
import ContestsComponent from "@/components/Contests";
export default {
  name: "Admin",
  components: {
    ContestsComponent,
    CoursesComponent,
    MatchesComponent,
    PlayerComponent,
  },
  data() {
    return {
      isActive: "matches",
      isActivePlayers: "edit",
    };
  },
  computed: {
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
  },
  async beforeMount() {},
  methods: {},
};
</script>

<style scoped>
.tab-contents .content {
  display: none;
}
.tab-contents .content.is-active {
  display: block;
}
</style>
