<template>
  <div class="column is-4">
    <div class="tableContests">
      <table class="table is-6 is-scrollable">
        <thead>
          <tr>
            <th scope="col">Tävling</th>
            <th scope="col">Aktiv</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="contest in contests" v-bind:key="contest.id" id="userID">
            <td>
              <input
                type="text"
                class="input"
                v-model="contest.name"
                @change="
                  editContest({
                    id: contest.id,
                    change: {
                      name: contest.name,
                    },
                  })
                "
              />
            </td>
            <td>
              <input
                type="checkbox"
                class="checkbox"
                v-model="contest.active"
                @change="
                  editContest({
                    id: contest.id,
                    change: {
                      active: contest.active,
                    },
                  })
                "
              />
            </td>
            <td>
              <button
                class="delete"
                @click="confirmDelete(contest.id)"
              ></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <br />
    <br />
    <input
      class="input is-2"
      type="text"
      v-model="newContest.name"
      id="new_contest"
    />
    <button @click="addContest()" class="button is-primary" id="button_add">
      Lägg till
    </button>
  </div>
</template>

<script>
import { toast } from "bulma-toast";
import axios from "axios";

export default {
  name: "ContestsComponent",
  data() {
    return {
      contests: null,
      newContest: { name: "" },
    };
  },
  beforeMount() {
    this.getContests();
  },
  methods: {
    confirmDelete(contest_id) {
      if (confirm("Vänligen bekräfta borttagning?")) {
        this.deleteContest(contest_id);
      } else {
        toast({
          message: "Radering avbruten",
          type: "is-info",
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: "bottom-right",
        });
      }
    },
    async getContests() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/contests/")
        .then((response) => {
          this.contests = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async addContest() {
      await axios
        .post("api/v1/contests/", this.newContest)
        .then(async () => {
          toast({
            message: "Ny tävling skapad",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
          await this.getContests();
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    async deleteContest(contest_id) {
      await axios
        .delete(`/api/v1/contests/${contest_id}/`)
        .then(() => {
          toast({
            message: "Tävling raderad",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
          this.getContests();
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    editContest(data) {
      return axios
        .patch(`/api/v1/contests/${data.id}/`, data.change)
        .then(() => {
          toast({
            message: "Tävling ändrad",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            console.log(message);
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
  },
};
</script>

<style scoped>
#new_contest {
  max-width: 66%;
  margin-left: 3%;
}

#button_add {
  margin-left: 12px;
}
</style>
