<template>
  <div class="container">
    <div class="columns is-multiline">
      <div v-if="teams.team_1_id && teams.team_2_id" class="column is-12">
        <h1 class="title">Lag</h1>
        <router-link v-if="isAdmin" to="/EditTeams" class="button is-info"
          ><strong>Editera</strong></router-link
        >
      </div>

      <div v-else class="column is-12">
        <button
          @click="generateTeams()"
          class="button is-primary"
          id="button_add"
        >
          Generera Lag
        </button>
      </div>

      <div v-if="teams.team_1_id" class="column is-6">
        <div>
          <h1 class="title" :style="{ color: teams.team_1_color }">
            Lag {{ teams.team_1_name }}
          </h1>
        </div>
        <div class="box">
          <table class="table is-full-mobile">
            <thead>
              <tr>
                <th>Namn</th>
                <th>Smeknamn</th>
                <th>Golf Id</th>
                <th>Hcp</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="player in players1" v-bind:key="player.id">
                <td>{{ player.fullname }}</td>
                <td>{{ player.nickname }}</td>
                <td>{{ player.golf_id }}</td>
                <td>{{ player.hcp }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="teams.team_2_id" class="column is-6">
        <div>
          <h1 class="title" :style="{ color: teams.team_2_color }">
            Lag {{ teams.team_2_name }}
          </h1>
        </div>
        <div class="box">
          <table class="table is-full-mobile">
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Name</th>
                <th>Golf Id</th>
                <th>Hcp</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="player in players2" v-bind:key="player.id">
                <td>{{ player.fullname }}</td>
                <td>{{ player.nickname }}</td>
                <td>{{ player.golf_id }}</td>
                <td>{{ player.hcp }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Teams",
  data() {
    return {
      players1: [],
      players2: [],
      newTeam: { name: "Lagnamn", color: 1 },
      teams: this.$store.getters.teamDetail,
      newColor: { color: "Black", color_html: "Black" },
    };
  },
  computed: {
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
  },
  beforeMount() {
    this.getPlayers();
  },
  methods: {
    async createColor() {
      this.$store.commit("setIsLoading", true);

      await axios
        .post("api/v1/colors/", this.newColor)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async generateTeams() {
      await this.createColor();
      if (this.teams.team_1_id === null) {
        await this.addTeam();
      }
      await this.addTeam();
      await this.$store.dispatch("getTeams");
      this.teams = this.$store.getters.teamDetail;
    },
    async addTeam() {
      this.$store.commit("setIsLoading", true);

      await axios
        .post("api/v1/teams/", this.newTeam)
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getPlayers() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("api/v1/players/")
        .then((response) => {
          // Divide players into corresponding team array
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].team === Number(this.teams.team_1_id)) {
              this.players1.push(response.data[i]);
            } else if (response.data[i].team === Number(this.teams.team_2_id)) {
              this.players2.push(response.data[i]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
  },
};
</script>
