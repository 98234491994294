<template>
  <!--  <div class="column is-full-mobile">-->
  <!--    <h1 class="title">LiveScores</h1>-->
  <!--  </div>-->

  <div class="tabs is-small is-centered is-toggle">
    <ul>
      <li v-bind:class="{ 'is-active': isActive === 'multiballs' }">
        <a v-on:click="isActive = 'multiballs'">Multiballs</a>
      </li>
      <li v-bind:class="{ 'is-active': isActive === 'singles' }">
        <a v-on:click="isActive = 'singles'">Singles</a>
      </li>
      <li v-bind:class="{ 'is-active': isActive === 'total' }">
        <a v-on:click="isActive = 'total'">Total</a>
      </li>
      <li v-bind:class="{ 'is-active': isActive === 'stats' }">
        <a v-on:click="isActive = 'stats'">Stats</a>
      </li>
    </ul>
  </div>

  <div class="tab-contents">
    <div
      class="content"
      v-bind:class="{ 'is-active': isActive === 'multiballs' }"
    >
      <!--      multiball content-->
      <div class="column is-4">
        <div>
          <h1 class="subtitle is-4">Fourballs</h1>
        </div>
        <table class="standing">
          <thead>
            <tr>
              <th :style="{ color: teams.team_1_color }">
                {{ teams.team_1_name }}
              </th>
              <th></th>
              <th :style="{ color: teams.team_2_color }">
                {{ teams.team_2_name }}
              </th>
              <th>Hole</th>
              <th class="is-hidden-touch"></th>
            </tr>
          </thead>
          <tbody id="standing">
            <tr v-for="fourball in fourballs" v-bind:key="fourball.id">
              <td>
                {{ fourball.contestant_1_1.nickname }} (<b>{{
                  getMatchStrokes(
                    fourball.contestant_1_1.strokes,
                    0,
                    fourball.type.name
                  )
                }}</b
                >) / {{ fourball.contestant_1_2.nickname }} (<b>{{
                  getMatchStrokes(
                    fourball.contestant_1_2.strokes,
                    0,
                    fourball.type.name
                  )
                }}</b
                >)
              </td>
              <td class="standing">
                <b
                  ><router-link
                    :style="getStyle(fourball.current.leader)"
                    :to="{ name: 'Score', params: { id: fourball.match_id } }"
                    >{{ fourball.current.standing }}</router-link
                  ></b
                >
              </td>
              <td>
                {{ fourball.contestant_2_1.nickname }} (<b>{{
                  getMatchStrokes(
                    fourball.contestant_2_1.strokes,
                    0,
                    fourball.type.name
                  )
                }}</b
                >) / {{ fourball.contestant_2_2.nickname }} (<b>{{
                  getMatchStrokes(
                    fourball.contestant_2_2.strokes,
                    0,
                    fourball.type.name
                  )
                }}</b
                >)
              </td>
              <td>
                <b>{{ fourball.current.on_hole }}</b>
              </td>
              <td class="is-hidden-touch">
                <button
                  class="delete"
                  @click="deactivateMatch(fourball.match_id)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="column is-4">
        <div>
          <h1 class="subtitle is-4">Foursomes</h1>
        </div>
        <table class="standing">
          <thead>
            <tr>
              <th :style="{ color: teams.team_1_color }">
                {{ teams.team_1_name }}
              </th>
              <th></th>
              <th :style="{ color: teams.team_2_color }">
                {{ teams.team_2_name }}
              </th>
              <th>Hole</th>
              <th class="is-hidden-touch"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="foursome in foursomes" v-bind:key="foursome.id">
              <td>
                {{ foursome.contestant_1_1.nickname }} /
                {{ foursome.contestant_1_2.nickname }} (<b>{{
                  getMatchStrokes(
                    foursome.contestant_1_1.strokes,
                    foursome.contestant_1_2.strokes,
                    foursome.type.name
                  )
                }}</b
                >)
              </td>
              <td>
                <b
                  ><router-link
                    :style="getStyle(foursome.current.leader)"
                    :to="{ name: 'Score', params: { id: foursome.match_id } }"
                    >{{ foursome.current.standing }}</router-link
                  ></b
                >
              </td>
              <td>
                {{ foursome.contestant_2_1.nickname }} /
                {{ foursome.contestant_2_2.nickname }} (<b>{{
                  getMatchStrokes(
                    foursome.contestant_2_1.strokes,
                    foursome.contestant_2_2.strokes,
                    foursome.type.name
                  )
                }}</b
                >)
              </td>
              <td>
                <b>{{ foursome.current.on_hole }}</b>
              </td>
              <td class="is-hidden-touch">
                <button
                  class="delete"
                  @click="deactivateMatch(foursome.match_id)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="column is-4">
        <div>
          <h1 class="subtitle is-4">Contests</h1>
        </div>
        <table class="table is-4">
          <thead>
            <tr>
              <th id="game">Game</th>
              <th>Winner</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="contest in contests" v-bind:key="contest.id">
              <td>{{ contest.name }}</td>
              <td>
                <div class="field is-2">
                  <select
                    v-model="contest.winner"
                    class="select"
                    data-native-menu="false"
                    @change="
                      editContest({
                        id: contest.id,
                        change: {
                          winner: contest.winner,
                        },
                      })
                    "
                    :style="getStyle(getTeamId(contest.winner))"
                  >
                    <option
                      v-for="winnerOption in winnerOptions"
                      v-bind:style="
                        winnerOption.team === Number(teams.team_1_id)
                          ? { color: teams.team_1_color }
                          : { color: teams.team_2_color }
                      "
                      v-bind:value="winnerOption.value"
                      v-bind:key="winnerOption.value"
                    >
                      {{ winnerOption.name }}
                    </option>
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="content" v-bind:class="{ 'is-active': isActive === 'singles' }">
      <!--      single content-->
      <div class="column is-4">
        <div>
          <h1 class="subtitle is-4">Singles</h1>
        </div>
        <table class="standing">
          <thead>
            <tr>
              <th :style="{ color: teams.team_1_color }">
                {{ teams.team_1_name }}
              </th>
              <th></th>
              <th :style="{ color: teams.team_2_color }">
                {{ teams.team_2_name }}
              </th>
              <th>Hole</th>
              <th class="is-hidden-touch"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="single in singles" v-bind:key="single.id">
              <td>
                {{ single.contestant_1_1.nickname }} (<b>{{
                  getMatchStrokes(
                    single.contestant_1_1.strokes,
                    0,
                    single.type.name
                  )
                }}</b
                >)
              </td>
              <td class="standing">
                <b
                  ><router-link
                    :style="getStyle(single.current.leader)"
                    :to="{ name: 'Score', params: { id: single.match_id } }"
                    >{{ single.current.standing }}</router-link
                  ></b
                >
              </td>
              <td>
                {{ single.contestant_2_1.nickname }} (<b>{{
                  getMatchStrokes(
                    single.contestant_2_1.strokes,
                    0,
                    single.type.name
                  )
                }}</b
                >)
              </td>
              <td>
                <b>{{ single.current.on_hole }}</b>
              </td>
              <td class="is-hidden-touch">
                <button
                  class="delete"
                  @click="deactivateMatch(single.match_id)"
                ></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="content" v-bind:class="{ 'is-active': isActive === 'total' }">
      <!--      total content-->
      <div class="column is-4">
        <div>
          <h1 class="subtitle is-4">Team Standing</h1>
        </div>
        <table class="table is-4" id="team_standing">
          <thead>
            <tr>
              <th :style="{ color: teams.team_1_color }">
                {{ teams.team_1_name }}
              </th>
              <th></th>
              <th :style="{ color: teams.team_2_color }">
                {{ teams.team_2_name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ teamScores.team_1.fourball }}</td>
              <td><b>Fourballs</b></td>
              <td>{{ teamScores.team_2.fourball }}</td>
            </tr>
            <tr>
              <td>{{ teamScores.team_1.foursome }}</td>
              <td><b>Foursomes</b></td>
              <td>{{ teamScores.team_2.foursome }}</td>
            </tr>
            <br />
            <tr>
              <td>{{ teamScores.team_1.contest }}</td>
              <td><b>Contests</b></td>
              <td>{{ teamScores.team_2.contest }}</td>
            </tr>
            <br />
            <tr>
              <td>{{ teamScores.team_1.single }}</td>
              <td><b>Singles</b></td>
              <td>{{ teamScores.team_2.single }}</td>
            </tr>
            <br />
            <tr>
              <td>{{ team1_total }}</td>
              <td><b>Total</b></td>
              <td>{{ team2_total }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="content" v-bind:class="{ 'is-active': isActive === 'stats' }">
      <!--      stats content-->
      <div class="column is-4">
        <div>
          <h1 class="subtitle is-4">Team Standing</h1>
        </div>

        <table class="table is-4">
          <thead>
            <tr>
              <th>Nickname</th>
              <th>Team</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="player in players" v-bind:key="player.id">
              <td :style="getStyle(player.team)">{{ player.nickname }}</td>
              <td
                :style="getStyle(player.team)"
                v-if="player.team === Number(teams.team_1_id)"
              >
                {{ teams.team_1_name }}
              </td>
              <td
                :style="getStyle(player.team)"
                v-else-if="player.team === Number(teams.team_2_id)"
              >
                {{ teams.team_2_name }}
              </td>
              <td>{{ player.points.total }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";
export default {
  name: "Main",
  data() {
    return {
      user_isAdmin: sessionStorage.getItem("isAdmin") === "true",
      user_nickname: sessionStorage.getItem("nickname"),
      courseData: this.$store.getters.courseData,
      winnerOptions: [{ value: null, name: "Not Finished", team: null }],
      players: [],
      teamScores: {
        team_1: { single: 0.0, foursome: 0.0, fourball: 0.0, contest: 0.0 },
        team_2: { single: 0.0, foursome: 0.0, fourball: 0.0, contest: 0.0 },
      },
      isActive: "multiballs",
      singles: [],
      foursomes: [],
      fourballs: [],
      contests: [{ id: null, name: null, winner: null }],
      teams: this.$store.getters.teamDetail,
      white_html: "GhostWhite ",
      black_html: "#000000",
    };
  },
  computed: {
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
    team1_total: function () {
      return (
        this.teamScores.team_1.single +
        this.teamScores.team_1.foursome +
        this.teamScores.team_1.fourball +
        this.teamScores.team_1.contest
      );
    },
    team2_total: function () {
      return (
        this.teamScores.team_2.single +
        this.teamScores.team_2.foursome +
        this.teamScores.team_2.fourball +
        this.teamScores.team_2.contest
      );
    },
  },
  async beforeMount() {
    await this.getScores();
    await this.getContests();
    await this.getPlayers();
    await this.getTeamContestPoints();
    // console.log(JSON.stringify(this.fourballs));
  },
  methods: {
    getMatchStrokes(strokes, strokes2, type) {
      if (type === "Single") {
        return strokes;
      } else if (type === "Foursome") {
        return Math.round((strokes + strokes2) / 4);
      } else if (type === "Fourball") {
        return Math.round(strokes * 0.45);
      }
    },
    editContest(contest) {
      return axios
        .patch(`/api/v1/contests/${contest.id}/`, contest.change)
        .then(() => {
          toast({
            message: "Winner added",
            type: "is-success",
            dismissible: true,
            pauseOnHover: true,
            duration: 2000,
            position: "bottom-right",
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            let message = "";
            for (const property in error.response.data) {
              error.response.data[property].forEach(function (item) {
                message += item;
              });
            }
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(JSON.stringify(message));
          } else if (error.request) {
            // The request was made but no response was received
            toast({
              message: "Error",
              type: "is-danger",
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: "bottom-right",
            });
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    },
    async addTeamScores(result, type) {
      if (result.leader === "team_1") {
        if (type === "Single") {
          this.teamScores.team_1.single++;
        } else if (type === "Foursome") {
          this.teamScores.team_1.foursome++;
        } else if (type === "Fourball") {
          this.teamScores.team_1.fourball++;
        }
      } else if (result.leader === "team_2") {
        if (type === "Single") {
          this.teamScores.team_2.single++;
        } else if (type === "Foursome") {
          this.teamScores.team_2.foursome++;
        } else if (type === "Fourball") {
          this.teamScores.team_2.fourball++;
        }
      } else {
        if (type === "Single") {
          this.teamScores.team_1.single = this.teamScores.team_1.single + 0.5;
          this.teamScores.team_2.single = this.teamScores.team_2.single + 0.5;
        } else if (type === "Foursome") {
          this.teamScores.team_1.foursome =
            this.teamScores.team_1.foursome + 0.5;
          this.teamScores.team_2.foursome =
            this.teamScores.team_2.foursome + 0.5;
        } else if (type === "Fourball") {
          this.teamScores.team_1.fourball =
            this.teamScores.team_1.fourball + 0.5;
          this.teamScores.team_2.fourball =
            this.teamScores.team_2.fourball + 0.5;
        }
      }
    },
    getTeamContestPoints() {
      for (let i = 0; i < this.players.length; i++) {
        if (this.players[i].team === 1) {
          this.teamScores.team_1.contest =
            this.teamScores.team_1.contest + this.players[i].points.contest;
        } else if (this.players[i].team === 2) {
          this.teamScores.team_2.contest =
            this.teamScores.team_2.contest + this.players[i].points.contest;
        }
      }
    },
    getPlayerPoints(player_id, player_team) {
      let points = {
        single: 0.0,
        foursome: 0.0,
        fourball: 0.0,
        contest: 0.0,
        total: 0.0,
      };
      let team =
        player_team === Number(this.teams.team_1_id) ? "team_1" : "team_2";
      for (let i = 0; i < this.singles.length; i++) {
        // check singles results and add points if finished
        if (!this.singles[i].current.in_play) {
          if (
            this.singles[i].current.leader === team &&
            (this.singles[i].contestant_1_1.id === player_id ||
              this.singles[i].contestant_2_1.id === player_id)
          ) {
            points.single++;
          } else if (
            this.singles[i].current.leader === null &&
            (this.singles[i].contestant_1_1.id === player_id ||
              this.singles[i].contestant_2_1.id === player_id)
          ) {
            points.single = points.single + 0.5;
          }
        }
      }
      for (let i = 0; i < this.foursomes.length; i++) {
        // check foursome results and add points if finished
        if (!this.foursomes[i].current.in_play) {
          if (
            this.foursomes[i].current.leader === team &&
            (this.foursomes[i].contestant_1_1.id === player_id ||
              this.foursomes[i].contestant_1_2.id === player_id ||
              this.foursomes[i].contestant_2_1.id === player_id ||
              this.foursomes[i].contestant_2_2.id === player_id)
          ) {
            points.single++;
          } else if (
            this.foursomes[i].current.leader === null &&
            (this.foursomes[i].contestant_1_1.id === player_id ||
              this.foursomes[i].contestant_1_2.id === player_id ||
              this.foursomes[i].contestant_2_1.id === player_id ||
              this.foursomes[i].contestant_2_2.id === player_id)
          ) {
            points.foursome = points.foursome + 0.5;
          }
        }
      }
      for (let i = 0; i < this.fourballs.length; i++) {
        // check fourball results and add points if finished
        if (!this.fourballs[i].current.in_play) {
          if (
            this.fourballs[i].current.leader === team &&
            (this.fourballs[i].contestant_1_1.id === player_id ||
              this.fourballs[i].contestant_1_2.id === player_id ||
              this.fourballs[i].contestant_2_1.id === player_id ||
              this.fourballs[i].contestant_2_2.id === player_id)
          ) {
            points.single++;
          } else if (
            this.fourballs[i].current.leader === null &&
            (this.fourballs[i].contestant_1_1.id === player_id ||
              this.fourballs[i].contestant_1_2.id === player_id ||
              this.fourballs[i].contestant_2_1.id === player_id ||
              this.fourballs[i].contestant_2_2.id === player_id)
          ) {
            points.fourball = points.fourball + 0.5;
          }
        }
      }
      for (let i = 0; i < this.contests.length; i++) {
        // check extra contests and add points if finished
        if (this.contests[i].winner === player_id) {
          points.contest++;
        }
      }
      points.total =
        points.single + points.fourball + points.foursome + points.contest;
      return points;
    },
    async getPlayers() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("api/v1/players/")
        .then((response) => {
          // Divide players into corresponding team array
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].user.is_active) {
              this.players.push(response.data[i]);
            }
          }

          for (let i = 0; i < this.players.length; i++) {
            this.players[i].points = this.getPlayerPoints(
              this.players[i].user.id,
              this.players[i].team
            );
            this.winnerOptions.push({
              value: this.players[i].user.id,
              name: this.players[i].nickname,
              team: this.players[i].team,
            });
          }
          this.players.sort((a, b) => {
            return b.points.total - a.points.total;
          });
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    getTeamId(player_id) {
      let player_team = null;
      for (let i = 0; i < this.players.length; i++) {
        if (player_id === this.players[i].id) {
          player_team = this.players[i].team;
        }
      }
      return player_team;
    },
    getStyle(value) {
      if (value === Number(this.teams.team_1_id) || value === "team_1") {
        return { color: this.teams.team_1_color };
      } else if (value === Number(this.teams.team_2_id) || value === "team_2") {
        return { color: this.teams.team_2_color };
      } else {
        return { color: this.black_html };
      }
    },
    gameInPlay(match_type, standing) {
      let no_of_holes = match_type === "Single" ? 18 : 9;
      let holes_played =
        match_type === 2 ? standing.on_hole - 10 : standing.on_hole - 1;

      if (standing.standing > no_of_holes - holes_played) {
        return {
          on_hole: "F",
          standing: standing.standing + "/" + (no_of_holes - holes_played),
          leader: standing.leader,
          in_play: false,
        };
      } else if (no_of_holes === holes_played) {
        return {
          on_hole: "F",
          standing: standing.standing,
          leader: standing.leader,
          in_play: false,
        };
      } else {
        return {
          on_hole: standing.on_hole,
          standing: standing.standing,
          leader: standing.leader,
          in_play: true,
        };
      }
    },
    async getContests() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/contests/")
        .then((response) => {
          this.contests = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async getScores() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("/api/v1/scores/")
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].current = this.gameInPlay(
              response.data[i].type.name,
              response.data[i].current
            );
            if (response.data[i].type.name === "Single") {
              this.singles.push(response.data[i]);
            } else if (response.data[i].type.name === "Foursome") {
              this.foursomes.push(response.data[i]);
            } else if (response.data[i].type.name === "Fourball") {
              this.fourballs.push(response.data[i]);
            }
            if (!response.data[i].current.in_play) {
              this.addTeamScores(
                response.data[i].current,
                response.data[i].type.name
              );
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.$store.commit("setIsLoading", false);
    },
    async deactivateMatch(match_id) {
      if (
        confirm("Vill du deaktivera matchen? All resultatdata kommer raderas.")
      ) {
        this.$store.commit("setIsLoading", true);
        let payload = { status: "CREATED" };

        axios
          .patch(`/api/v1/matches/${match_id}/`, payload)
          .then(() => {
            toast({
              message: "Match deaktiverad",
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
          })
          .catch((error) => {
            console.log(error);
          });

        window.location.reload();

        this.$store.commit("setIsLoading", false);
      }
    },
  },
};
</script>

<style scoped>
.tab-contents .content {
  display: none;
}
.tab-contents .content.is-active {
  display: block;
}
.standing tbody > tr > :nth-child(2) {
  width: 60px;
  text-align: center;
  font-size: x-large;
  padding-left: 2px;
  padding-right: 2px;
}
.standing tbody > tr > :nth-child(1) {
  text-align: center;
  font-size: large;
  padding-left: 2px;
  padding-right: 2px;
}
.standing tbody > tr > :nth-child(3) {
  text-align: center;
  font-size: large;
  padding-left: 2px;
  padding-right: 2px;
}
.standing tbody > tr > :nth-child(4) {
  text-align: center;
  font-weight: bold;
  font-size: large;
}

.standing tbody > tr {
  vertical-align: center;
}

.column {
  margin-left: -12px;
}

#team_standing {
  text-align: center;
}
</style>
