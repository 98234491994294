<template>
  <div class="container">
    <div class="columns is-multiline">
      <div class="column is-12">
        <h1 class="title">
          <b :style="{ color: players.team_1_color }">{{
            players.team_1_player
          }}</b>
          vs
          <b :style="{ color: players.team_2_color }">{{
            players.team_2_player
          }}</b>
        </h1>
      </div>

      <div class="column is-5">
        <div>
          <h1 class="subtitle" v-if="currentStanding.leader">
            <b :style="{ color: standing.style }">{{ standing.leader }}</b>
            leads
            <b :style="{ color: standing.style }">{{ standing.standing }}</b>
            through hole: {{ standing.hole }}
          </h1>
          <h1 class="subtitle" v-else>
            <b>AS </b> through hole: {{ standing.hole }}
          </h1>
        </div>
        <br />
        <div class="box is-6">
          <table class="table is-small">
            <thead>
              <tr>
                <th>Hole</th>
                <th>Par</th>
                <th>Hcp</th>
                <th>Length</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="hole in courseData" v-bind:key="hole.hole_nr">
                <td>{{ hole.hole_nr }}</td>
                <td>{{ hole.hole_par }}</td>
                <td>{{ hole.hole_index }}</td>
                <td>{{ hole.hole_length }}</td>
                <td>
                  <div class="field is-2">
                    <select
                      class="select"
                      v-model="hole.result"
                      :style="getStyle(hole.result)"
                      @change="setScore(hole)"
                      :disabled="lockScore"
                    >
                      <option value="Not Played">Not Played</option>
                      <option value="AS">AS</option>
                      <option
                        :style="{ color: teams.team_1_color }"
                        value="Team_1"
                      >
                        {{ players.team_1_player }}
                      </option>
                      <option
                        :style="{ color: teams.team_2_color }"
                        value="Team_2"
                      >
                        {{ players.team_2_player }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button
          v-if="inPlay || isAdmin"
          @click="unlockMatch()"
          :disabled="!matchClosed"
          class="button is-info"
        >
          Correct Scores
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "bulma-toast";

export default {
  name: "Score",
  data() {
    return {
      matchClosed: false,
      user_id: sessionStorage.getItem("id"),
      courseData: this.$store.getters.courseData,
      teams: {
        team_1_id: sessionStorage.getItem("team_1_id"),
        team_1_name: sessionStorage.getItem("team_1_name"),
        team_1_color: sessionStorage.getItem("team_1_color"),
        team_2_id: sessionStorage.getItem("team_2_id"),
        team_2_name: sessionStorage.getItem("team_2_name"),
        team_2_color: sessionStorage.getItem("team_2_color"),
      },
      currentStanding: { on_hole: 1, standing: null, leader: null },
      scoreId: null,
      matchData: null,
      holes: [
        { hole_1: null },
        { hole_2: null },
        { hole_3: null },
        { hole_4: null },
        { hole_5: null },
        { hole_6: null },
        { hole_7: null },
        { hole_8: null },
        { hole_9: null },
        { hole_10: null },
        { hole_11: null },
        { hole_12: null },
        { hole_13: null },
        { hole_14: null },
        { hole_15: null },
        { hole_16: null },
        { hole_17: null },
        { hole_18: null },
      ],
    };
  },
  async beforeMount() {
    await this.getScore();
    // console.log("user id: ", this.user_id);
    // console.log(JSON.stringify(this.teams));
  },
  computed: {
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
    lockScore: function () {
      if (this.isAdmin) {
        return false;
      } else {
        return !!(!this.inPlay || this.matchClosed);
      }
    },
    inPlay: function () {
      if (this.matchData) {
        if (this.matchData.type.name === "Single") {
          return (
            Number(this.user_id) === this.matchData.contestant_1_1.id ||
            Number(this.user_id) === this.matchData.contestant_2_1.id
          );
        } else {
          return (
            Number(this.user_id) === this.matchData.contestant_1_1.id ||
            Number(this.user_id) === this.matchData.contestant_1_2.id ||
            Number(this.user_id) === this.matchData.contestant_2_1.id ||
            Number(this.user_id) === this.matchData.contestant_2_2.id
          );
        }
      } else {
        return false;
      }
    },
    standing: function () {
      if (this.currentStanding.leader === "team_1") {
        return {
          leader: this.players.team_1_player,
          style: this.teams.team_1_color,
          standing: this.currentStanding.standing,
          hole:
            this.currentStanding.on_hole === "F"
              ? "F"
              : this.currentStanding.on_hole - 1,
        };
      } else if (this.currentStanding.leader === "team_2") {
        return {
          leader: this.players.team_2_player,
          style: this.teams.team_2_color,
          standing: this.currentStanding.standing,
          hole:
            this.currentStanding.on_hole === "F"
              ? "F"
              : this.currentStanding.on_hole - 1,
        };
      } else {
        return {
          leader: "",
          style: "",
          standing: this.currentStanding.standing,
          hole:
            this.currentStanding.on_hole === "F"
              ? "F"
              : this.currentStanding.on_hole - 1,
        };
      }
    },
    players: function () {
      if (this.matchData) {
        if (this.matchData.type.name === "Single") {
          return {
            team_1_player: this.matchData.contestant_1_1.nickname,
            team_1_color: this.teams.team_1_color,
            team_2_player: this.matchData.contestant_2_1.nickname,
            team_2_color: this.teams.team_2_color,
          };
        } else {
          return {
            team_1_player:
              this.matchData.contestant_1_1.nickname +
              "/" +
              this.matchData.contestant_1_2.nickname,
            team_1_color: this.teams.team_1_color,
            team_2_player:
              this.matchData.contestant_2_1.nickname +
              "/" +
              this.matchData.contestant_2_2.nickname,
            team_2_color: this.teams.team_2_color,
          };
        }
      } else {
        return {
          team_1_player: "",
          team_1_color: this.teams.team_1_color,
          team_2_player: "",
          team_2_color: this.teams.team_2_color,
        };
      }
    },
  },
  methods: {
    unlockMatch() {
      this.matchClosed = !this.matchClosed;
    },
    lockMatch() {
      let no_of_holes = this.matchData.type.name === "Single" ? 18 : 9;
      let holes_played = 0;
      let team_1 = 0;
      let team_2 = 0;
      for (let i = 0; i < this.courseData.length; i++) {
        if (this.courseData[i].result !== "Not Played") {
          holes_played++;
          if (this.courseData[i].result === "Team_1") {
            team_1++;
          } else if (this.courseData[i].result === "Team_2") {
            team_2++;
          }
        }
      }
      let standing =
        team_1 === team_2
          ? 0
          : team_1 > team_2
          ? team_1 - team_2
          : team_2 - team_1;
      return standing > no_of_holes - holes_played;
    },
    getStyle(value) {
      if (value === "AS" || value === "Not Played") {
        return null;
      } else if (value === "Team_1") {
        return { color: this.teams.team_1_color };
      } else if (value === "Team_2") {
        return { color: this.teams.team_2_color };
      }
    },
    async getScore() {
      this.$store.commit("setIsLoading", true);

      await axios
        .get("api/v1/scores/?match=" + this.$route.params.id)
        .then((response) => {
          this.currentStanding = response.data[0].current;
          this.scoreId = response.data[0].id;
          this.matchData = response.data[0];
          if (response.data[0].type.name === "Single") {
            this.courseData[0].result = response.data[0].hole_1;
            this.courseData[1].result = response.data[0].hole_2;
            this.courseData[2].result = response.data[0].hole_3;
            this.courseData[3].result = response.data[0].hole_4;
            this.courseData[4].result = response.data[0].hole_5;
            this.courseData[5].result = response.data[0].hole_6;
            this.courseData[6].result = response.data[0].hole_7;
            this.courseData[7].result = response.data[0].hole_8;
            this.courseData[8].result = response.data[0].hole_9;
            this.courseData[9].result = response.data[0].hole_10;
            this.courseData[10].result = response.data[0].hole_11;
            this.courseData[11].result = response.data[0].hole_12;
            this.courseData[12].result = response.data[0].hole_13;
            this.courseData[13].result = response.data[0].hole_14;
            this.courseData[14].result = response.data[0].hole_15;
            this.courseData[15].result = response.data[0].hole_16;
            this.courseData[16].result = response.data[0].hole_17;
            this.courseData[17].result = response.data[0].hole_18;
          } else if (response.data[0].type.name === "Foursome") {
            if (this.courseData.length === 18) {
              for (let i = 0; i < 9; i++) {
                this.courseData.shift();
              }
            }
            this.courseData[0].result = response.data[0].hole_10;
            this.courseData[1].result = response.data[0].hole_11;
            this.courseData[2].result = response.data[0].hole_12;
            this.courseData[3].result = response.data[0].hole_13;
            this.courseData[4].result = response.data[0].hole_14;
            this.courseData[5].result = response.data[0].hole_15;
            this.courseData[6].result = response.data[0].hole_16;
            this.courseData[7].result = response.data[0].hole_17;
            this.courseData[8].result = response.data[0].hole_18;
          } else if (response.data[0].type.name === "Fourball") {
            if (this.courseData.length === 18) {
              for (let i = 0; i < 9; i++) {
                this.courseData.pop();
              }
            }
            this.courseData[0].result = response.data[0].hole_1;
            this.courseData[1].result = response.data[0].hole_2;
            this.courseData[2].result = response.data[0].hole_3;
            this.courseData[3].result = response.data[0].hole_4;
            this.courseData[4].result = response.data[0].hole_5;
            this.courseData[5].result = response.data[0].hole_6;
            this.courseData[6].result = response.data[0].hole_7;
            this.courseData[7].result = response.data[0].hole_8;
            this.courseData[8].result = response.data[0].hole_9;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.matchClosed = this.lockMatch();
      this.$store.commit("setIsLoading", false);
    },
    async setScore(hole) {
      let proceed = true;
      if (this.lockMatch()) {
        if (confirm("This will finish the game! Proceed?")) {
          proceed = true;
          this.matchClosed = true;
        } else {
          proceed = false;
        }
      }
      if (proceed) {
        let request = this.holes[hole.hole_nr - 1];
        for (let key of Object.keys(request)) {
          request[key] = hole.result;
        }
        // console.log(JSON.stringify(request));

        this.$store.commit("setIsLoading", true);

        await axios
          .patch(`api/v1/scores/${this.scoreId}/`, request)
          .then(async () => {
            toast({
              message: "Hole " + hole.hole_nr + "result: " + hole.result,
              type: "is-success",
              dismissible: true,
              pauseOnHover: true,
              duration: 3000,
              position: "bottom-right",
            });
            await this.getScore();
          })
          .catch((error) => {
            console.log(error);
          });
        this.$store.commit("setIsLoading", false);
      }
    },
  },
};
</script>
<style scoped>
.table td,
.table th {
  border: 1px solid #dbdbdb;
  border-width: 0 0 1px;
  padding: 0.5em 0.25em;
  vertical-align: top;
}
.select {
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: top;
  font-weight: bold;
}
</style>
