import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    user: {
      id: "",
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      isAdmin: "",
      nickname: "",
      hcp: "",
      golf_id: "",
      team: "",
    },
    team_1: null,
    team_2: null,
    isLoading: false,
    isAuthenticated: false,
    token: null,
    course: null,
  },
  mutations: {
    UPDATE_USERDETAIL(state, userDetail) {
      sessionStorage.setItem("id", userDetail.id);
      sessionStorage.setItem("username", userDetail.username);
      sessionStorage.setItem("first_name", userDetail.first_name);
      sessionStorage.setItem("last_name", userDetail.last_name);
      sessionStorage.setItem("email", userDetail.email);
      sessionStorage.setItem("isAdmin", userDetail.is_staff);
      sessionStorage.setItem("nickname", userDetail.nickname);
      sessionStorage.setItem("hcp", userDetail.hcp);
      sessionStorage.setItem("golf_id", userDetail.golf_id);
      sessionStorage.setItem("team", userDetail.team);
      state.user = userDetail;
      // state.user.id = userDetail.id;
      // state.user.username = userDetail.username;
      // state.user.first_name = userDetail.first_name;
      // state.user.last_name = userDetail.last_name;
      // state.user.email = userDetail.email;
      // state.user.isAdmin = userDetail.is_staff;
      // state.user.nickname = userDetail.nickname;
      // state.user.hcp = userDetail.hcp;
      // state.user.golf_id = userDetail.golf_id;
      // state.user.team = userDetail.team;
    },
    async initializeStore(dispatch) {
      await dispatch("getTeams");
      // if (localStorage.getItem("token")) {
      //   state.token = localStorage.getItem("token");
      //   state.isAuthenticated = true;
      // } else {
      //   state.token = "";
      //   state.isAuthenticated = false;
      // }
    },
    setCourseData(state, courseData) {
      state.course = courseData;
      sessionStorage.setItem("hole_1_par", courseData[0].par);
      sessionStorage.setItem("hole_1_index", courseData[0].index);
      sessionStorage.setItem("hole_1_length", courseData[0].length);
      sessionStorage.setItem("hole_2_par", courseData[1].par);
      sessionStorage.setItem("hole_2_index", courseData[1].index);
      sessionStorage.setItem("hole_2_length", courseData[1].length);
      sessionStorage.setItem("hole_3_par", courseData[2].par);
      sessionStorage.setItem("hole_3_index", courseData[2].index);
      sessionStorage.setItem("hole_3_length", courseData[2].length);
      sessionStorage.setItem("hole_4_par", courseData[3].par);
      sessionStorage.setItem("hole_4_index", courseData[3].index);
      sessionStorage.setItem("hole_4_length", courseData[3].length);
      sessionStorage.setItem("hole_5_par", courseData[4].par);
      sessionStorage.setItem("hole_5_index", courseData[4].index);
      sessionStorage.setItem("hole_5_length", courseData[4].length);
      sessionStorage.setItem("hole_6_par", courseData[5].par);
      sessionStorage.setItem("hole_6_index", courseData[5].index);
      sessionStorage.setItem("hole_6_length", courseData[5].length);
      sessionStorage.setItem("hole_7_par", courseData[6].par);
      sessionStorage.setItem("hole_7_index", courseData[6].index);
      sessionStorage.setItem("hole_7_length", courseData[6].length);
      sessionStorage.setItem("hole_8_par", courseData[7].par);
      sessionStorage.setItem("hole_8_index", courseData[7].index);
      sessionStorage.setItem("hole_8_length", courseData[7].length);
      sessionStorage.setItem("hole_9_par", courseData[8].par);
      sessionStorage.setItem("hole_9_index", courseData[8].index);
      sessionStorage.setItem("hole_9_length", courseData[8].length);
      sessionStorage.setItem("hole_10_par", courseData[9].par);
      sessionStorage.setItem("hole_10_index", courseData[9].index);
      sessionStorage.setItem("hole_10_length", courseData[9].length);
      sessionStorage.setItem("hole_11_par", courseData[10].par);
      sessionStorage.setItem("hole_11_index", courseData[10].index);
      sessionStorage.setItem("hole_11_length", courseData[10].length);
      sessionStorage.setItem("hole_12_par", courseData[11].par);
      sessionStorage.setItem("hole_12_index", courseData[11].index);
      sessionStorage.setItem("hole_12_length", courseData[11].length);
      sessionStorage.setItem("hole_13_par", courseData[12].par);
      sessionStorage.setItem("hole_13_index", courseData[12].index);
      sessionStorage.setItem("hole_13_length", courseData[12].length);
      sessionStorage.setItem("hole_14_par", courseData[13].par);
      sessionStorage.setItem("hole_14_index", courseData[13].index);
      sessionStorage.setItem("hole_14_length", courseData[13].length);
      sessionStorage.setItem("hole_15_par", courseData[14].par);
      sessionStorage.setItem("hole_15_index", courseData[14].index);
      sessionStorage.setItem("hole_15_length", courseData[14].length);
      sessionStorage.setItem("hole_16_par", courseData[15].par);
      sessionStorage.setItem("hole_16_index", courseData[15].index);
      sessionStorage.setItem("hole_16_length", courseData[15].length);
      sessionStorage.setItem("hole_17_par", courseData[16].par);
      sessionStorage.setItem("hole_17_index", courseData[16].index);
      sessionStorage.setItem("hole_17_length", courseData[16].length);
      sessionStorage.setItem("hole_18_par", courseData[17].par);
      sessionStorage.setItem("hole_18_index", courseData[17].index);
      sessionStorage.setItem("hole_18_length", courseData[17].length);
    },
    setTeams(state, { teams_1, teams_2 }) {
      if (teams_1 != null) {
        state.team_1 = teams_1;
        sessionStorage.setItem("team_1_id", teams_1.id);
        sessionStorage.setItem("team_1_name", teams_1.name);
        sessionStorage.setItem("team_1_color", teams_1.color.color_html);
      }
      if (teams_2 != null) {
        state.team_2 = teams_2;
        sessionStorage.setItem("team_2_id", teams_2.id);
        sessionStorage.setItem("team_2_name", teams_2.name);
        sessionStorage.setItem("team_2_color", teams_2.color.color_html);
      }
    },
    setIsLoading(state, status) {
      state.isLoading = status;
    },
    setToken(state, token) {
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      sessionStorage.setItem("token", token);
      state.token = token;
      state.isAuthenticated = true;
    },
    removeToken(state) {
      axios.defaults.headers.common["Authorization"] = "";
      sessionStorage.removeItem("token");
      state.token = null;
      state.isAuthenticated = false;

      sessionStorage.removeItem("id");
      sessionStorage.removeItem("username");
      sessionStorage.removeItem("first_name");
      sessionStorage.removeItem("last_name");
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("isAdmin");
      sessionStorage.removeItem("nickname");
      sessionStorage.removeItem("hcp");
      sessionStorage.removeItem("golf_id");
      sessionStorage.removeItem("team");
      sessionStorage.removeItem("team_1_id");
      sessionStorage.removeItem("team_1_name");
      sessionStorage.removeItem("team_1_color");
      sessionStorage.removeItem("team_2_id");
      sessionStorage.removeItem("team_2_name");
      sessionStorage.removeItem("team_2_color");
      sessionStorage.removeItem("hole_1_par");
      sessionStorage.removeItem("hole_1_index");
      sessionStorage.removeItem("hole_1_length");
      sessionStorage.removeItem("hole_2_par");
      sessionStorage.removeItem("hole_2_index");
      sessionStorage.removeItem("hole_2_length");
      sessionStorage.removeItem("hole_3_par");
      sessionStorage.removeItem("hole_3_index");
      sessionStorage.removeItem("hole_3_length");
      sessionStorage.removeItem("hole_4_par");
      sessionStorage.removeItem("hole_4_index");
      sessionStorage.removeItem("hole_4_length");
      sessionStorage.removeItem("hole_5_par");
      sessionStorage.removeItem("hole_5_index");
      sessionStorage.removeItem("hole_5_length");
      sessionStorage.removeItem("hole_6_par");
      sessionStorage.removeItem("hole_6_index");
      sessionStorage.removeItem("hole_6_length");
      sessionStorage.removeItem("hole_7_par");
      sessionStorage.removeItem("hole_7_index");
      sessionStorage.removeItem("hole_7_length");
      sessionStorage.removeItem("hole_8_par");
      sessionStorage.removeItem("hole_8_index");
      sessionStorage.removeItem("hole_8_length");
      sessionStorage.removeItem("hole_9_par");
      sessionStorage.removeItem("hole_9_index");
      sessionStorage.removeItem("hole_9_length");
      sessionStorage.removeItem("hole_10_par");
      sessionStorage.removeItem("hole_10_index");
      sessionStorage.removeItem("hole_10_length");
      sessionStorage.removeItem("hole_11_par");
      sessionStorage.removeItem("hole_11_index");
      sessionStorage.removeItem("hole_11_length");
      sessionStorage.removeItem("hole_12_par");
      sessionStorage.removeItem("hole_12_index");
      sessionStorage.removeItem("hole_12_length");
      sessionStorage.removeItem("hole_13_par");
      sessionStorage.removeItem("hole_13_index");
      sessionStorage.removeItem("hole_13_length");
      sessionStorage.removeItem("hole_14_par");
      sessionStorage.removeItem("hole_14_index");
      sessionStorage.removeItem("hole_14_length");
      sessionStorage.removeItem("hole_15_par");
      sessionStorage.removeItem("hole_15_index");
      sessionStorage.removeItem("hole_15_length");
      sessionStorage.removeItem("hole_16_par");
      sessionStorage.removeItem("hole_16_index");
      sessionStorage.removeItem("hole_16_length");
      sessionStorage.removeItem("hole_17_par");
      sessionStorage.removeItem("hole_17_index");
      sessionStorage.removeItem("hole_17_length");
      sessionStorage.removeItem("hole_18_par");
      sessionStorage.removeItem("hole_18_index");
      sessionStorage.removeItem("hole_18_length");
      state.user.id = null;
      state.user.username = "";
      state.user.first_name = "";
      state.user.last_name = "";
      state.user.email = "";
      state.user.isAdmin = null;
      state.user.nickname = "";
      state.user.hcp = "";
      state.user.golf_id = "";
      state.user.team = null;
    },
  },
  getters: {
    isAdmin: () => {
      return sessionStorage.getItem("isAdmin") === "true";
    },
    token: () => {
      return sessionStorage.getItem("token");
    },
    isLoggedIn: () => {
      return sessionStorage.getItem("token") != null;
    },
    userDetail: () => {
      return {
        id: sessionStorage.getItem("id"),
        username: sessionStorage.getItem("username"),
        first_name: sessionStorage.getItem("first_name"),
        last_name: sessionStorage.getItem("last_name"),
        email: sessionStorage.getItem("email"),
        isAdmin: sessionStorage.getItem("isAdmin"),
        nickname: sessionStorage.getItem("nickname"),
        hcp: sessionStorage.getItem("hcp"),
        golf_id: sessionStorage.getItem("golf_id"),
        team: sessionStorage.getItem("team"),
      };
    },
    teamDetail: () => {
      return {
        team_1_id: sessionStorage.getItem("team_1_id"),
        team_1_name: sessionStorage.getItem("team_1_name"),
        team_1_color: sessionStorage.getItem("team_1_color"),
        team_2_id: sessionStorage.getItem("team_2_id"),
        team_2_name: sessionStorage.getItem("team_2_name"),
        team_2_color: sessionStorage.getItem("team_2_color"),
      };
    },
    courseData: () => {
      return [
        {
          hole_nr: 1,
          hole_par: sessionStorage.getItem("hole_1_par"),
          hole_index: sessionStorage.getItem("hole_1_index"),
          hole_length: sessionStorage.getItem("hole_1_length"),
        },
        {
          hole_nr: 2,
          hole_par: sessionStorage.getItem("hole_2_par"),
          hole_index: sessionStorage.getItem("hole_2_index"),
          hole_length: sessionStorage.getItem("hole_2_length"),
        },
        {
          hole_nr: 3,
          hole_par: sessionStorage.getItem("hole_3_par"),
          hole_index: sessionStorage.getItem("hole_3_index"),
          hole_length: sessionStorage.getItem("hole_3_length"),
        },
        {
          hole_nr: 4,
          hole_par: sessionStorage.getItem("hole_4_par"),
          hole_index: sessionStorage.getItem("hole_4_index"),
          hole_length: sessionStorage.getItem("hole_4_length"),
        },
        {
          hole_nr: 5,
          hole_par: sessionStorage.getItem("hole_5_par"),
          hole_index: sessionStorage.getItem("hole_5_index"),
          hole_length: sessionStorage.getItem("hole_5_length"),
        },
        {
          hole_nr: 6,
          hole_par: sessionStorage.getItem("hole_6_par"),
          hole_index: sessionStorage.getItem("hole_6_index"),
          hole_length: sessionStorage.getItem("hole_6_length"),
        },
        {
          hole_nr: 7,
          hole_par: sessionStorage.getItem("hole_7_par"),
          hole_index: sessionStorage.getItem("hole_7_index"),
          hole_length: sessionStorage.getItem("hole_7_length"),
        },
        {
          hole_nr: 8,
          hole_par: sessionStorage.getItem("hole_8_par"),
          hole_index: sessionStorage.getItem("hole_8_index"),
          hole_length: sessionStorage.getItem("hole_8_length"),
        },
        {
          hole_nr: 9,
          hole_par: sessionStorage.getItem("hole_9_par"),
          hole_index: sessionStorage.getItem("hole_9_index"),
          hole_length: sessionStorage.getItem("hole_9_length"),
        },
        {
          hole_nr: 10,
          hole_par: sessionStorage.getItem("hole_10_par"),
          hole_index: sessionStorage.getItem("hole_10_index"),
          hole_length: sessionStorage.getItem("hole_10_length"),
        },
        {
          hole_nr: 11,
          hole_par: sessionStorage.getItem("hole_11_par"),
          hole_index: sessionStorage.getItem("hole_11_index"),
          hole_length: sessionStorage.getItem("hole_11_length"),
        },
        {
          hole_nr: 12,
          hole_par: sessionStorage.getItem("hole_12_par"),
          hole_index: sessionStorage.getItem("hole_12_index"),
          hole_length: sessionStorage.getItem("hole_12_length"),
        },
        {
          hole_nr: 13,
          hole_par: sessionStorage.getItem("hole_13_par"),
          hole_index: sessionStorage.getItem("hole_13_index"),
          hole_length: sessionStorage.getItem("hole_13_length"),
        },
        {
          hole_nr: 14,
          hole_par: sessionStorage.getItem("hole_14_par"),
          hole_index: sessionStorage.getItem("hole_14_index"),
          hole_length: sessionStorage.getItem("hole_14_length"),
        },
        {
          hole_nr: 15,
          hole_par: sessionStorage.getItem("hole_15_par"),
          hole_index: sessionStorage.getItem("hole_15_index"),
          hole_length: sessionStorage.getItem("hole_15_length"),
        },
        {
          hole_nr: 16,
          hole_par: sessionStorage.getItem("hole_16_par"),
          hole_index: sessionStorage.getItem("hole_16_index"),
          hole_length: sessionStorage.getItem("hole_16_length"),
        },
        {
          hole_nr: 17,
          hole_par: sessionStorage.getItem("hole_17_par"),
          hole_index: sessionStorage.getItem("hole_17_index"),
          hole_length: sessionStorage.getItem("hole_17_length"),
        },
        {
          hole_nr: 18,
          hole_par: sessionStorage.getItem("hole_18_par"),
          hole_index: sessionStorage.getItem("hole_18_index"),
          hole_length: sessionStorage.getItem("hole_18_length"),
        },
      ];
    },
  },
  actions: {
    async login({ commit, dispatch }, payload) {
      commit("setIsLoading", true);
      await axios
        .post("api/v1/token/login/", payload)
        .then(async (response) => {
          await commit("setToken", response.data.auth_token);
          await dispatch("getTeams");
          await dispatch("getCourseData");
          await dispatch("getUser");
        })
        .catch((error) => {
          if (error.response) {
            console.log(JSON.stringify(error.response.data));
            // Request made and server responded
            for (const property in error.response.data) {
              console.log(property);
              let item = error.response.data[property];
              console.log(item);
            }
          } else if (error.request) {
            // The request was made but no response was received
            this.toast.error("Something went wrong");
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      commit("setIsLoading", false);
    },
    async getUser({ commit }) {
      await axios
        .get("api/v1/users/me/")
        .then((response) => {
          commit("UPDATE_USERDETAIL", response.data);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },
    async logout({ commit }) {
      await axios
        .post("api/v1/token/logout/")
        .then(() => {
          commit("removeToken");
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },
    async getTeams({ commit }) {
      await axios
        .get("api/v1/teams/")
        .then((response) => {
          commit("setTeams", {
            teams_1: response.data[0] ? response.data[0] : null,
            teams_2: response.data[1] ? response.data[1] : null,
          });
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },
    async getCourseData({ commit }) {
      await axios
        .get("api/v1/courses/?selected=true")
        .then((response) => {
          commit("setCourseData", response.data[0].course_data);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },
  },
  modules: {},
});
