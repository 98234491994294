import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

// Vue.use(VueSimpleAlert);

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API; // "http://127.0.0.1:8000/";
// axios.defaults.baseURL = "https://api.ryderkupp.se/";

createApp(App).use(store).use(router, axios).mount("#app");

axios.interceptors.request.use(async (config) => {
  if (store.getters.isLoggedIn) {
    let token = store.getters.token;
    config.headers.Authorization = "Token " + token;
  }
  return config;
});
